<template>
  <div class="w-full">
    <t-form
      ref="form"
      class="postal-form-step"
      @submit="onSubmit"
      @submit.prevent
    >
      <h6 class="input-header">
        Vul hier jouw postcode in
      </h6>

      <div class="postal-form-step__form">
        <PostalSeparateInputs
          class="postal-form-step__separate-inputs"
          :error-messages="errorMessages"
          @result="onResult"
          @change="onChange"
        />

        <FormStepSubmitButton
          id="submitPostal"
          class="postal-form-step__btn"
          text="Ga verder"
        />
      </div>
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import usesAddress from 'chimera/all/mixins/usesAddress'
import PostalSeparateInputs from 'chimera/all/components/form/fields/postal/PostalSeparateInputs.nl'
import TypeOpdracht from '~/pages/offertes-aanvragen/type-opdracht'

export default {
  name: 'PostalFormStep',

  components: {
    FormErrorMessages,
    PostalSeparateInputs
  },

  extends: AbstractFormStep,

  mixins: [usesAddress],

  /**
   * Pass through the submit event given from FormModal
   */
  created () {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed () {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * @param {object} result
     */
    async onPostalResult (result) {
      const { value, isValid } = result
      if (isValid) {
        await this.$store.dispatch('lead/rm', 'address-street')
        await this.$store.dispatch('lead/rm', 'address-city')
        await this.fetchStreetAndCityForPostal(value)
      }

      this.onResult(result)
    },

    /**
     * Transition
     */
    transition () {
      this.routeTo(TypeOpdracht)
    }
  }
}
</script>
